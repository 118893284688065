import { Box } from "@mantine/core";
import { useSinglePrismicDocument } from "@prismicio/react";
import { Header } from "../components/Header";

function Work() {
  const [document] = useSinglePrismicDocument("work");
  console.log("document:", document);

  return (
    <Box display="flex" className="page-container">
      <Header />
      <Box className="work-container">
        {/* <Title>{document?.data.redirecttovimeo}</Title>
        <NavLink
          href={document?.data.vimeo.url}
          label={document?.data.vimeo.url?.replace("https://", "")}
        /> */}
        {document?.data?.samplevideos?.map((video: any) => (
          <iframe
            className="work-video"
            width="560"
            height="315"
            src={video.url.url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ))}
      </Box>
    </Box>
  );
}

export default Work;
