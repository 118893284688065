import { Header } from "../components/Header";
import {
  Box,
  Button,
  Grid,
  Textarea,
  TextInput,
  Title,
  Text,
} from "@mantine/core";
import { useSinglePrismicDocument } from "@prismicio/react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

emailjs.init({
  publicKey: "p5hYgTRqUpFpWmC_a",
  // Do not allow headless browsers
  blockHeadless: true,
  limitRate: {
    // Set the limit rate for the application
    id: "app",
    // Allow 1 request per 10s
    throttle: 10000,
  },
});

function Contact() {
  const [document] = useSinglePrismicDocument("contact");
  console.log("document:", document);
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus("loading");

    try {
      await emailjs.sendForm(
        "service_pauu61f",
        "template_l1llh7k",
        e.target as HTMLFormElement
      );
      setStatus("success");
    } catch (e) {
      setStatus("error");
    }
  };

  return (
    <Box display="flex" className="page-container">
      <Header />
      {status === "success" ? (
        <Box display="flex" className="contact-container">
          <Text>{document?.data.thankyoumessage}</Text>
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box display="flex" className="contact-container" px="sm">
            <Grid miw="40vw" justify="center">
              <Grid.Col span={12}>
                <Title order={1} mb="lg">
                  {document?.data.body}
                </Title>
                <TextInput
                  id="name"
                  name="name"
                  label="Name"
                  required
                  disabled={status === "loading"}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  id="email"
                  name="email"
                  label="Email"
                  required
                  disabled={status === "loading"}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea
                  id="message"
                  name="message"
                  label="Message"
                  required
                  component="textarea"
                  aria-multiline="true"
                  rows={6}
                  disabled={status === "loading"}
                />
                {status === "error" && (
                  <Text size="0.8rem" c="red" mt="xs">
                    There was an error sending your message. Please try again.
                  </Text>
                )}
              </Grid.Col>
              <Box mt="md">
                <Button
                  id="send_button"
                  type="submit"
                  disabled={status === "loading"}
                  bg="gray.7"
                  fw="lighter"
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Box>
        </form>
      )}
    </Box>
  );
}

export default Contact;
