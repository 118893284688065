import { Box, Burger, Drawer, Image, NavLink } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export const Header = () => {
  const path = window.location.pathname;

  const [open, { toggle }] = useDisclosure(false);

  return (
    <Box w="100%" pos="relative">
      <Box
        display="flex"
        w="100%"
        className="header"
        component="header"
        py="sm"
        px="xl"
      >
        <Box component="nav" visibleFrom="xs">
          <NavLink href="/work" label="Work" active={path === "/work"} />
          <NavLink
            href="/contact"
            label="Contact"
            active={path === "/contact"}
          />
        </Box>
        <Burger opened={open} onClick={toggle} hiddenFrom="xs" size="sm" />

        <a href="/">
          <Image w="150px" src="./images/image.png" id="header-logo" />
        </a>
      </Box>
      {open && (
        <Drawer opened={open} onClose={toggle} hiddenFrom="xs" size="100vw">
          <Box component="nav" display="flex" className="mobile-nav">
            <NavLink
              href="/work"
              label="Work"
              active={path === "/work"}
              mb="md"
            />
            <NavLink
              href="/contact"
              label="Contact"
              active={path === "/contact"}
              mb="md"
            />
          </Box>
        </Drawer>
      )}
    </Box>
  );
};
